<template>
  <div class="aiz-user-panel">
    
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
            <h1 class="h3">{{ $t('gong-dan-0') }}</h1>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 mx-auto mb-3" >
        <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition" @click="add">
          <span class="size-70px rounded-circle mx-auto bg-secondary d-flex align-items-center justify-content-center mb-3">
            <i class="las la-plus la-3x text-white"></i>
          </span>
          <div class="fs-20 text-primary">{{ $t('chuang-jian-gong-dan-0') }}</div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('gong-dan-0') }}</h5>
      </div>
      <div class="card-body">
        <table class="table  mb-0" :class="dataList.length == 0 ? ' footable aiz-table' : ''">
          <thead>
            <tr>
              <th >{{ $t('gong-dan-hao') }}</th>
              <th>{{ $t('fa-song-ri-qi') }}</th>
              <th >{{ $t('zhu-ti-1') }}</th>
              <th >{{ $t('zhuang-tai') }}</th>
              <th >{{ $t('cao-zuo') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in dataList" :key="i">
              <td>{{ item.ID }}</td>
              <td>{{ item.CreatedAt }}</td>
              <td>{{ item.Subject }}</td>
              <td >
                <span class="badge badge-inline badge-danger" v-if="item.Status == 2">{{ $t('jin-hang-zhong') }}</span>
                <span class="badge badge-inline badge-secondary" v-else-if="item.Status == 1">{{ $t('yi-da-kai') }}</span>
                <span class="badge badge-inline badge-success" v-else>{{ $t('yi-jie-jue') }}</span>
              </td>
              <td>
                <a  class="btn btn-styled btn-link py-1 px-0 icon-anim text-underline--none" @click="toDetail(item)">
                  {{ $t('cha-kan-xiang-qing') }} <i class="la la-angle-right text-sm"></i>
                </a>
              </td>
            </tr>
            
            <tr class="footable-empty" v-if="dataList.length == 0"><td colspan="6">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination mt-4" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
        </div>
      </div>
    </div>


    <Popup v-model="showPopup">
      <div class="popup-box " id="wallet_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('chuang-jian-gong-dan-0') }}</h5>
              <button type="button" class="close" @click="cancel"></button>
            </div>
            <form class="" >
              <div class="modal-body gry-bg px-3 pt-3">

                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('zhu-ti-1') }} <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-9">
                    <input type="text"  class="form-control mb-3"  :placeholder="$t('zhu-ti-1')" v-model="form.subject">
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('xiang-xi-shuo-ming') }} <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-9">
                    <textarea type="text" class="form-control mb-3" rows="3" v-model="form.desc" :placeholder="$t('shu-ru-ni-de-miao-shu')" ></textarea>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('zhao-pian') }} </label>
                  </div>
                  <div class="col-md-9">
                    <div class="mb-3">
                      <div class="input-group" data-toggle="aizuploader" data-type="image" data-multiple="true">
                        <div class="input-group-prepend">
                          <div class="input-group-text bg-soft-secondary font-weight-medium">{{ $t('liu-lan') }}</div>
                        </div>
                        <div class="form-control file-amount" @click="addFile(2)">{{ $t('xuan-ze-wen-jian') }}</div>
                        <input type="file" class="hide-btn" id="fileInput" @change="uploadFile">
                      </div>
                      <previewBar :fileList="fileList" @remove="removeFile"></previewBar>
                    </div>
                  </div>
                </div>
                <div class="mt-4 text-right">
                  <button type="button" class="btn btn-secondary" @click="cancel">{{ $t('qu-xiao') }}</button>
                  <button type="button" class="btn btn-primary ml-2" @click="submit">{{ $t('que-ding') }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>

  </div>
</template>
<script>
import pageBar from '@/components/page'
import {Popup} from 'vant'
import previewBar from '@/components/preview'
import { addWorkOrder, workOrderList, fileUpload } from '@/api/user'
export default {
  components: {
    pageBar,
    previewBar,
    Popup
  },
  data() {
    return {
      form: {
        subject: '',
        desc: '',
      },
      fileList: [],
      showPopup: false,
      userMoney: 10,
      dataList: [],
      page: {
        page: 1,
        limit: 10,
        total: 1
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      workOrderList().then(res => {
        this.dataList = res.data.Items
        this.page.total = res.data.Pagination.totalPage
      })
    },
    changePage(page) {
      this.page.page = page
      this.init()
    },
    cancel() {
      this.showPopup = false
    },
    add() {
      this.showPopup = true
    },
    addFile(type) {
      this.fileType = type
      document.getElementById('fileInput').click()
    },
    uploadFile() {
      let file = document.getElementById('fileInput').files[0]
      let fileName = file.name
      let form = new FormData()
      form.append('file', file)
      fileUpload(form).then(res => {
        if (res.code == 0) {
          this.fileList.push({
            id: res.data,
            url: `/upload/${res.data}`,
            name: fileName
          })
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    toDetail(data) {
      sessionStorage.setItem('ticketData', JSON.stringify(data))
      this.$router.push({
        name: 'ticketDetail',
        query: {
          id: data.ID
        }
      })
    },
    removeFile(i) {
      this.fileList.splice(i, 1)
    },
    submit() {
      if (!this.form.subject) {
        this.$toast.fail(this.$t('qing-shu-ru-zhu-ti'))
        return
      }
      if (!this.form.desc) {
        this.$toast.fail(this.$t('qing-shu-ru-miao-shu'))
        return
      }
      let form = new FormData()
      form.append('Subject', this.form.subject)
      form.append('Content', this.form.desc)
      form.append('Images', this.fileList.map(v => {
        return v.id
      }))
      addWorkOrder(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('chuang-jian-cheng-gong'))
          this.page.page = 1
          this.showPopup = false
          this.form.subject = ''
          this.form.desc = ''
          this.fileList = []
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>